import { Dialog } from "@headlessui/react";
import { Link } from "@remix-run/react";
import { v4 as uuid4 } from "uuid";
import { CURRENCY, DEFAULT_PRODUCT_IMAGE } from "../../config/constants";
import { useCart } from "../../context/CartContext";

export default function QuickViewDialog({
  isOpen,
  setIsOpen,
  product,
  selectedVariantData,
  quantityInCart,
}) {
  const {
    addToCart,
    isInCart,
    cartLoaded,
    getQuantity,
    updateQuantity,
    removeFromCart,
  } = useCart();
  const defaultImage = DEFAULT_PRODUCT_IMAGE;

  const currency = CURRENCY;

  const handleAddToCart = () => {
    addToCart(product, selectedVariantData, 1);
  };

  const id = uuid4();
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="fixed inset-0 z-10 flex items-start justify-center overflow-auto pt-[72px] lg:m-0 lg:p-4"
      aria-labelledby={`${id}-dialog-title`}
      aria-describedby={`${id}-dialog-description`}
    >
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={() => {
          setIsOpen(false);
        }}
      />

      <div className="relative bg-white rounded-tl-lg rounded-tr-lg lg:rounded-bl-lg lg:rounded-br-lg shadow-lg max-w-[1024px] lg:max-w-[1200px] w-full h-full overflow-scroll lg:h-auto z-20">
        <div
          id={`${id}-dialog-title`}
          className="fixed lg:relative top-[72px] shadow shadow-black/10 lg:shadow-none lg:top-0 rounded-tl-lg rounded-tr-lg left-0 right-0 px-4 h-[60px] bg-white border-b border-b-[#f0f0f0] text-lg font-bold flex items-center justify-between"
        >
          <button
            type="button"
            onClick={() => setIsOpen(false)}
            className="flex items-center gap-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>
            Back
          </button>

          <button onClick={() => setIsOpen(false)}>
            <svg width="28" height="28" viewBox="0 0 20 20">
              <path
                fill="none"
                style={{ strokeWidth: "1.06px", stroke: "currentcolor" }}
                d="M16,16 L4,4"
              ></path>
              <path
                fill="none"
                style={{ strokeWidth: "1.06px", stroke: "currentcolor" }}
                d="M16,4 L4,16"
              ></path>
            </svg>
          </button>
        </div>
        <div
          id={`${id}-dialog-description`}
          className="mt-[60px] lg:mt-0 py-4 px-4 text-sm text-gray-600"
        >
          <div className="grid grid-cols-12 auto-cols-fr w-full">
            <div className="col-span-12 lg:col-span-7 lg:border-r border-[#f0f0f0] border-solid">
              <Link to={`/products/${product.id}`}>
                <img
                  className="w-full hidden lg:block"
                  alt={product.title}
                  width="500"
                  height="500"
                  src={product?.image || defaultImage}
                />
                <img
                  className="w-full lg:hidden"
                  alt={product.title}
                  width="300"
                  height="200"
                  src={product?.image_300_200 || defaultImage}
                />
              </Link>
            </div>
            <div className="col-span-12 lg:col-span-5 pb-[30px] flex flex-col justify-between">
              <div className="px-[0] lg:pl-[30px] lg:pr-[30px] lg:py-[24px] flex flex-col gap-[12px]">
                <div className="pt-[30px]">
                  <div className="text-xl lg:text-2xl text-black mb-2 lg:mb-4 font-bold leading-[1.2]">
                    {product.title}
                  </div>
                  <div className="mb-[12px] lg:mb-0">
                    {/* Display price if available and weight is not required */}
                    {selectedVariantData?.price &&
                      !product?.is_weight_required && (
                        <div className="text-black inline-flex flex-row items-start gap-[2px] mt-[4px]">
                          <span
                            className="inline-block text-xs"
                            dangerouslySetInnerHTML={{ __html: currency }}
                          ></span>
                          <span className="text-[18px] leading-[18px]">
                            {
                              Math.floor(selectedVariantData.price)
                                .toFixed(2)
                                .split(".")[0]
                            }
                          </span>
                          <span className="inline-block text-xs">
                            {
                              Number((selectedVariantData.price % 1).toFixed(2))
                                .toString()
                                .split(".")[1]
                            }
                          </span>
                        </div>
                      )}

                    {/* Display estimated weight if weight is required */}
                    {product?.is_weight_required && (
                      <div>
                        <span className="text-xs opacity-60">
                          Est. Weight:{" "}
                          {selectedVariantData?.weight_without_unit}
                        </span>
                      </div>
                    )}

                    {/* Display price per unit if weight is required */}
                    {selectedVariantData?.price_per_unit &&
                      product?.is_weight_required && (
                        <div className="text-black inline-flex flex-row items-start gap-[2px] mt-[4px]">
                          <span
                            className="inline-block text-xs"
                            dangerouslySetInnerHTML={{ __html: currency }}
                          ></span>
                          <span className="text-[18px] leading-[18px]">
                            {
                              Math.floor(selectedVariantData.price_per_unit)
                                .toFixed(2)
                                .split(".")[0]
                            }
                          </span>
                          <span className="inline-block text-xs">
                            {
                              Number(
                                (
                                  selectedVariantData.price_per_unit % 1
                                ).toFixed(2)
                              )
                                .toString()
                                .split(".")[1]
                            }
                          </span>
                          <span className="opacity-60 text-[13px]">
                            /{selectedVariantData?.unit_type}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
                <div className="rte text-base text-black">
                  {product.description}
                </div>
                {cartLoaded ? (
                  <div className="relative w-full hidden lg:block">
                    {isInCart(product.id) ? (
                      <div className="bg-primary drop-shadow-md rounded-[30px] h-[50px] flex justify-between items-center">
                        {quantityInCart === 1 ? (
                          <button
                            onClick={() =>
                              removeFromCart(product.id, selectedVariantData.id)
                            }
                            aria-label="Remove from cart"
                            className="w-[40px] h-40px text-black text-lg flex justify-center items-center flex-grow"
                          >
                            <svg
                              fill="none"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g>
                                <path
                                  d="M19 6H5"
                                  style={{
                                    stroke: "black",
                                    strokeLinecap: "round",
                                    strokeWidth: "1.5",
                                  }}
                                ></path>
                                <path
                                  d="M14 5H10"
                                  style={{
                                    stroke: "black",
                                    strokeLinecap: "round",
                                    strokeWidth: "1.5",
                                  }}
                                ></path>
                                <path
                                  d="M6 10V21H18C18 20 18 10 18 10"
                                  style={{
                                    stroke: "black",
                                    strokeLinecap: "round",
                                    strokeWidth: "1.5",
                                  }}
                                ></path>
                              </g>
                            </svg>
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              updateQuantity(
                                product.id,
                                selectedVariantData.id,
                                quantityInCart - 1
                              )
                            }
                            aria-label="Decrease quantity"
                            className="w-[40px] h-40px text-black text-lg flex justify-center items-center flex-grow"
                          >
                            <svg
                              fill="none"
                              height="25"
                              viewBox="0 0 24 25"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20 12.6074H4"
                                style={{
                                  stroke: "black",
                                  strokeLinecap: "square",
                                  strokeWidth: "2",
                                }}
                              ></path>
                            </svg>
                          </button>
                        )}
                        <span className="text-black text-lg">
                          {product.is_weight_required &&
                          selectedVariantData?.price_per_unit
                            ? currency +
                              (
                                selectedVariantData.price * quantityInCart
                              ).toFixed(2)
                            : quantityInCart}
                        </span>
                        <button
                          onClick={() =>
                            updateQuantity(
                              product.id,
                              selectedVariantData.id,
                              quantityInCart + 1
                            )
                          }
                          className="w-[40px] h-40px flex justify-center items-center flex-grow"
                        >
                          <svg
                            fill="none"
                            height="25"
                            viewBox="0 0 24 25"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20 12.6074H4"
                              style={{
                                stroke: "black",
                                strokeLinecap: "square",
                                strokeWidth: "2",
                              }}
                            ></path>
                            <path
                              d="M12 4.60742V20.6074"
                              style={{
                                stroke: "black",
                                strokeLinecap: "square",
                                strokeWidth: "2",
                              }}
                            ></path>
                          </svg>
                        </button>
                      </div>
                    ) : (
                      <button
                        className="w-full h-[50px] text-black text-lg drop-shadow-md rounded-[30px] bg-primary text-body flex justify-center items-center gap-2 "
                        style={{ boxShadow: "0 4px 3px rgb(0 0 0 / 0.1)" }}
                        title="Add to cart"
                        onClick={handleAddToCart}
                      >
                        <span>Add to cart</span>

                        {product.is_weight_required &&
                        selectedVariantData?.price_per_unit ? (
                          <span className="flex justify-center items-center gap-2">
                            <span>
                              <svg
                                fill="none"
                                height="4"
                                viewBox="0 0 5 4"
                                width="5"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="2.5"
                                  cy="2"
                                  fill="black"
                                  r="2"
                                ></circle>
                              </svg>
                            </span>
                            <span>
                              {currency}
                              {selectedVariantData.price} (
                              {"~" + selectedVariantData.weight})
                            </span>
                          </span>
                        ) : null}
                      </button>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div
          id={`${id}-dialog-footer`}
          className="fixed lg:relative lg:hidden z-10 bottom-[0px] lg:top-0 left-0 right-0 px-2 h-[60px] bg-white border-t border-t-[#f0f0f0] text-base flex items-center justify-between"
        >
          {cartLoaded ? (
            <div className="relative w-full">
              {isInCart(product.id) ? (
                <div className="bg-primary drop-shadow-md rounded-[30px] h-[50px] flex justify-between items-center">
                  {quantityInCart === 1 ? (
                    <button
                      onClick={() =>
                        removeFromCart(product.id, selectedVariantData.id)
                      }
                      aria-label="Remove from cart"
                      className="w-[40px] h-40px text-black text-lg flex justify-center items-center flex-grow"
                    >
                      <svg
                        fill="none"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <path
                            d="M19 6H5"
                            style={{
                              stroke: "black",
                              strokeLinecap: "round",
                              strokeWidth: "1.5",
                            }}
                          ></path>
                          <path
                            d="M14 5H10"
                            style={{
                              stroke: "black",
                              strokeLinecap: "round",
                              strokeWidth: "1.5",
                            }}
                          ></path>
                          <path
                            d="M6 10V21H18C18 20 18 10 18 10"
                            style={{
                              stroke: "black",
                              strokeLinecap: "round",
                              strokeWidth: "1.5",
                            }}
                          ></path>
                        </g>
                      </svg>
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        updateQuantity(
                          product.id,
                          selectedVariantData.id,
                          quantityInCart - 1
                        )
                      }
                      aria-label="Decrease quantity"
                      className="w-[40px] h-40px text-black text-lg flex justify-center items-center flex-grow"
                    >
                      <svg
                        fill="none"
                        height="25"
                        viewBox="0 0 24 25"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 12.6074H4"
                          style={{
                            stroke: "black",
                            strokeLinecap: "square",
                            strokeWidth: "2",
                          }}
                        ></path>
                      </svg>
                    </button>
                  )}
                  <span className="text-black text-lg">
                    {product.is_weight_required &&
                    selectedVariantData?.price_per_unit
                      ? currency +
                        (selectedVariantData.price * quantityInCart).toFixed(2)
                      : quantityInCart}
                  </span>
                  <button
                    onClick={() =>
                      updateQuantity(
                        product.id,
                        selectedVariantData.id,
                        quantityInCart + 1
                      )
                    }
                    className="w-[40px] h-40px flex justify-center items-center flex-grow"
                  >
                    <svg
                      fill="none"
                      height="25"
                      viewBox="0 0 24 25"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 12.6074H4"
                        style={{
                          stroke: "black",
                          strokeLinecap: "square",
                          strokeWidth: "2",
                        }}
                      ></path>
                      <path
                        d="M12 4.60742V20.6074"
                        style={{
                          stroke: "black",
                          strokeLinecap: "square",
                          strokeWidth: "2",
                        }}
                      ></path>
                    </svg>
                  </button>
                </div>
              ) : (
                <button
                  className="w-full h-[50px] text-black text-lg drop-shadow-md rounded-[30px] bg-primary text-body flex justify-center items-center gap-2 "
                  style={{ boxShadow: "0 4px 3px rgb(0 0 0 / 0.1)" }}
                  title="Add to cart"
                  onClick={handleAddToCart}
                >
                  <span>Add to cart</span>

                  {product.is_weight_required &&
                  selectedVariantData?.price_per_unit ? (
                    <span className="flex justify-center items-center gap-2">
                      <span>
                        <svg
                          fill="none"
                          height="4"
                          viewBox="0 0 5 4"
                          width="5"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="2.5" cy="2" fill="black" r="2"></circle>
                        </svg>
                      </span>
                      <span>
                        {currency}
                        {selectedVariantData.price} (
                        {"~" + selectedVariantData.weight})
                      </span>
                    </span>
                  ) : null}
                </button>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </Dialog>
  );
}
