import { useState, useEffect } from "react";
import { useCart } from "../../context/CartContext";
import { Link } from "@remix-run/react";
import {
  CURRENCY,
  DEFAULT_PRODUCT_IMAGE,
  DEFAULT_SMALL_PRODUCT_IMAGE,
} from "../../config/constants";
import QuickViewDialog from "./QuickViewDialog";

export default function ProductGridItem({ product }) {
  const currency = CURRENCY;
  const [selectedVariant, setSelectedVariant] = useState(0);
  const [variants, setVariants] = useState([]);
  const defaultImage = DEFAULT_SMALL_PRODUCT_IMAGE;
  const selectedVariantData = variants[selectedVariant];
  const {
    addToCart,
    isInCart,
    cartLoaded,
    getQuantity,
    updateQuantity,
    removeFromCart,
  } = useCart();

  const [isOpen, setIsOpen] = useState(false);

  const quantityInCart = getQuantity(product.id);

  useEffect(() => {
    let updatedVariants;

    if (product.is_weight_required) {
      updatedVariants = product.variants.map((variant) => {
        const price = parseFloat(variant.price);
        const weight = parseFloat(
          variant.weight.replace(` ${variant.unit_type}`, "")
        );
        const price_per_unit = (price / weight).toFixed(2);
        return {
          ...variant,
          price: price.toFixed(2),
          price_per_unit,
          weight_without_unit: weight.toFixed(2),
        };
      });
    } else {
      updatedVariants = product.variants.map((variant) => {
        const price = parseFloat(variant.price);
        return {
          ...variant,
          price: price.toFixed(2),
        };
      });
    }

    // Reverse the variants in both cases
    updatedVariants = updatedVariants.reverse();

    // Update the state with the modified variants
    setVariants(updatedVariants);
  }, [product]);

  const handleAddToCart = () => {
    addToCart(product, selectedVariantData, 1);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="relative">
        <button
          type="button"
          className="w-full overflow-hidden rounded-[10px] border-2 border-solid border-[#f0f0f0]"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <img
            className="w-auto mx-auto max-h-[170px] h-auto"
            alt={product.title}
            width="300"
            height="200"
            src={product?.image_300_200 || defaultImage}
          />
        </button>

        {cartLoaded ? (
          <div className="absolute bottom-[10px] right-[10px]">
            {isInCart(product.id) ? (
              <div className="bg-white text-black drop-shadow-md rounded-[30px] h-[40px] w-[97px] flex justify-between items-center">
                {quantityInCart === 1 ? (
                  <button
                    onClick={() =>
                      removeFromCart(product.id, selectedVariantData.id)
                    }
                    aria-label="Remove from cart"
                    className="w-[40px] h-40px flex justify-center items-center"
                  >
                    <svg
                      fill="none"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <path
                          d="M19 6H5"
                          style={{
                            stroke: "black",
                            strokeLinecap: "round",
                            strokeWidth: "1.5",
                          }}
                        ></path>
                        <path
                          d="M14 5H10"
                          style={{
                            stroke: "black",
                            strokeLinecap: "round",
                            strokeWidth: "1.5",
                          }}
                        ></path>
                        <path
                          d="M6 10V21H18C18 20 18 10 18 10"
                          style={{
                            stroke: "black",
                            strokeLinecap: "round",
                            strokeWidth: "1.5",
                          }}
                        ></path>
                      </g>
                    </svg>
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      updateQuantity(
                        product.id,
                        selectedVariantData.id,
                        quantityInCart - 1
                      )
                    }
                    aria-label="Decrease quantity"
                    className="w-[40px] h-40px flex justify-center items-center"
                  >
                    <svg
                      fill="none"
                      height="25"
                      viewBox="0 0 24 25"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 12.6074H4"
                        style={{
                          stroke: "black",
                          strokeLinecap: "square",
                          strokeWidth: "2",
                        }}
                      ></path>
                    </svg>
                  </button>
                )}
                <span>{quantityInCart}</span>
                <button
                  onClick={() =>
                    updateQuantity(
                      product.id,
                      selectedVariantData.id,
                      quantityInCart + 1
                    )
                  }
                  className="w-[40px] h-40px flex justify-center items-center"
                >
                  <svg
                    fill="none"
                    height="25"
                    viewBox="0 0 24 25"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 12.6074H4"
                      style={{
                        stroke: "black",
                        strokeLinecap: "square",
                        strokeWidth: "2",
                      }}
                    ></path>
                    <path
                      d="M12 4.60742V20.6074"
                      style={{
                        stroke: "black",
                        strokeLinecap: "square",
                        strokeWidth: "2",
                      }}
                    ></path>
                  </svg>
                </button>
              </div>
            ) : (
              <button
                className="w-[40px] h-[40px] drop-shadow-md rounded-[30px] bg-white text-body flex justify-center items-center gap-2 "
                style={{ boxShadow: "0 4px 3px rgb(0 0 0 / 0.1)" }}
                title="Add to cart"
                onClick={handleAddToCart}
              >
                <span>
                  <svg
                    fill="none"
                    height="25"
                    viewBox="0 0 24 25"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 12.6074H4"
                      style={{
                        stroke: "black",
                        strokeLinecap: "square",
                        strokeWidth: "2",
                      }}
                    ></path>
                    <path
                      d="M12 4.60742V20.6074"
                      style={{
                        stroke: "black",
                        strokeLinecap: "square",
                        strokeWidth: "2",
                      }}
                    ></path>
                  </svg>
                </span>
                <span className="hidden"> Add </span>
              </button>
            )}
          </div>
        ) : null}
      </div>
      <div className="flex flex-col">
        <div className="text-left overflow-hidden mb-1">
          <Link
            prefetch="intent"
            className="block text-black text-body leading-[18px] text-[14px] lg:leading-[1.4] lg:text-base text-ellipsis hover:text-gold focus:text-gold"
            to={`/products/${product.id}`}
          >
            <span>{product.title}</span>
          </Link>
        </div>
        <div className="mb-[12px] lg:mb-0">
          {/* Display price if available and weight is not required */}
          {selectedVariantData?.price && !product?.is_weight_required && (
            <div className="text-black inline-flex flex-row items-start gap-[2px] mt-[4px]">
              <span
                className="inline-block text-xs"
                dangerouslySetInnerHTML={{ __html: currency }}
              ></span>
              <span className="text-[18px] leading-[18px]">
                {Math.floor(selectedVariantData.price).toFixed(2).split(".")[0]}
              </span>
              <span className="inline-block text-xs">
                {
                  Number((selectedVariantData.price % 1).toFixed(2))
                    .toString()
                    .split(".")[1]
                }
              </span>
            </div>
          )}

          {/* Display estimated weight if weight is required */}
          {product?.is_weight_required && (
            <div>
              <span className="text-xs text-black/60">
                Est. Weight: {selectedVariantData?.weight_without_unit}
              </span>
            </div>
          )}

          {/* Display price per unit if weight is required */}
          {selectedVariantData?.price_per_unit &&
            product?.is_weight_required && (
              <div className="text-black inline-flex flex-row items-start gap-[2px] mt-[4px]">
                <span
                  className="inline-block text-xs"
                  dangerouslySetInnerHTML={{ __html: currency }}
                ></span>
                <span className="text-[18px] leading-[18px]">
                  {
                    Math.floor(selectedVariantData.price_per_unit)
                      .toFixed(2)
                      .split(".")[0]
                  }
                </span>
                <span className="inline-block text-xs">
                  {
                    Number((selectedVariantData.price_per_unit % 1).toFixed(2))
                      .toString()
                      .split(".")[1]
                  }
                </span>
                <span className="opacity-60 text-[13px]">
                  /{selectedVariantData?.unit_type}
                </span>
              </div>
            )}
        </div>
      </div>
      <QuickViewDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        product={product}
        selectedVariantData={selectedVariantData}
        quantityInCart={quantityInCart}
      />
    </div>
  );
}
